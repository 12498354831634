import './styles.css';

import React, { useEffect, useState } from 'react';

import Footer from '../../components/footer';
import { Modal } from 'antd';
import Navbar from '../../components/navbar';

const GROUPS = [
    {
        "title": "Women Group",
        "description": "Join our Women's Group for fellowship, encouragement, and spiritual growth. Connect with other women as we study God's Word, pray together, and support one another in our journey of faith.",
        "image": require("../../assets/Women-IYK.jpeg")
    },
    {
        "title": "Men Group",
        "description": "Our Men's Group provides a space for men to grow deeper in their relationship with Christ and with one another. Engage in meaningful discussions, accountability, and service opportunities as we pursue godly manhood together.",
        "image": require("../../assets/Men-Group-IYK.jpg")
    },
    {
        "title": "Youth Group",
        "description": "Calling all youth! Join our Youth Group for fun, fellowship, and spiritual growth. Experience dynamic worship, relevant teaching, and opportunities for service and outreach as we journey together in faith.",
        "image": require("../../assets/Youth-IYK-2.jpeg")
    }
];

const Groups: React.FC = () => {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        option: '',
    });

    const [errors, setErrors] = useState({
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        option: '',
    });

    const [modalVisible, setModalVisible] = useState(false);
    const [modalMessage, setModalMessage] = useState("Sending your response...");
    const [formSubmitted, setFormSubmitted] = useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        validateField(name, value);
    };

    const validateField = (name: string, value: string) => {
        let error = '';
        switch (name) {
            case 'firstName':
            case 'lastName':
                if (value.trim() === '') {
                    error = 'This field is required';
                }
                break;
            case 'phoneNumber':
                const phonePattern = /^[0-9]{10}$/;
                if (!phonePattern.test(value)) {
                    error = 'Enter a valid 10-digit phone number';
                }
                break;
            case 'email':
                const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
                if (!emailPattern.test(value)) {
                    error = 'Enter a valid email address';
                }
                break;
            case 'option':
                if (value === '') {
                    error = 'Please select an option';
                }
                break;
            default:
                break;
        }
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: error,
        }));
    };

    const validateForm = () => {
        let valid = true;
        for (let key in formData) {
            validateField(key, formData[key as keyof typeof formData]);
            if (formData[key as keyof typeof formData].trim() === '') {
                valid = false;
            }
        }
        return valid;
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (validateForm()) {
            setModalVisible(true);
            setFormSubmitted(true);
            // Simulate form submission delay
            setTimeout(() => {
                setModalMessage("Response sent successfully!");
            }, 3000);
            
        }
    };

    const handleCloseModal = () => {
        setModalVisible(false);
        setModalMessage("Sending your response...");
        setFormSubmitted(false);
        setFormData({
            firstName: '',
            lastName: '',
            phoneNumber: '',
            email: '',
            option: '',
        })
    };

    useEffect(() => {
        const handleResize = () => setScreenWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [window.innerWidth]);

    return (
        <>
            <Navbar />
            <div className="main-container-groups">
                <div className='heading'>
                    <div className='heading-column'>
                        <h1>A Deeper Look at<br></br>Our Church Groups</h1>
                    </div>
                    <div className='heading-column'>
                        <h3>
                            At our church, we believe in the importance of community and fellowship. Our church groups provide opportunities for individuals to connect, grow, and serve together in smaller, more intimate settings. Whether you're looking to deepen your faith, build meaningful relationships, or use your gifts and talents for God's kingdom, there's a group for you.
                        </h3>
                    </div>
                </div>
                {
                    GROUPS.map((item, index: any) =>
                        <div className='groups' key={index}>
                            {(index % 2 === 0 || screenWidth < 768) ?
                                <>
                                    <img src={item.image} alt={item.title} />
                                    <div className='groups-content'>
                                        <h1>{item.title}</h1>
                                        <p>{item.description}</p>
                                    </div>
                                </> :
                                <>
                                    <div className='groups-content'>
                                        <h1>{item.title}</h1>
                                        <p>{item.description}</p>
                                    </div>
                                    <img src={item.image} alt={item.title} />
                                </>
                            }
                        </div>
                    )
                }

                <form className="form-container" onSubmit={handleSubmit}>
                    <h1>Let us know what group you would like to get connected to!</h1>
                    <div className="form-row">
                        <div className="form-groups">
                            <label htmlFor="firstName">First Name</label>
                            <input
                                type="text"
                                id="firstName"
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleChange}
                                required
                            />
                            {errors.firstName && <span className="error">{errors.firstName}</span>}
                        </div>
                        <div className="form-groups">
                            <label htmlFor="lastName">Last Name</label>
                            <input
                                type="text"
                                id="lastName"
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleChange}
                                required
                            />
                            {errors.lastName && <span className="error">{errors.lastName}</span>}
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-groups">
                            <label htmlFor="phoneNumber">Phone Number</label>
                            <input
                                type="tel"
                                id="phoneNumber"
                                name="phoneNumber"
                                value={formData.phoneNumber}
                                onChange={handleChange}
                                required
                            />
                            {errors.phoneNumber && <span className="error">{errors.phoneNumber}</span>}
                        </div>
                        <div className="form-groups">
                            <label htmlFor="email">Email</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                            {errors.email && <span className="error">{errors.email}</span>}
                        </div>
                    </div>
                    <div className="form-groups">
                        <label htmlFor="option">Groups</label>
                        <select
                            id="option"
                            name="option"
                            value={formData.option}
                            onChange={handleChange}
                            required
                        >
                            <option value="">Select a group</option>
                            <option value="Men">Men</option>
                            <option value="Women">Women</option>
                            <option value="Youth">Youth</option>
                        </select>
                        {errors.option && <span className="error">{errors.option}</span>}
                    </div>
                    <div className="form-groups">
                        <button type="submit">SUBMIT</button>
                    </div>
                </form>

                
                    <Modal 
                        open={modalVisible}
                        footer={null}
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            height: '90vh',
                        }}
                    >
                        <div className="modal-content">
                            <h2>{modalMessage}</h2>
                            {formSubmitted && modalMessage === "Response sent successfully!" && (
                                <button onClick={handleCloseModal}>Close</button>
                            )}
                        </div>
                    </Modal>
            </div>
            <Footer />
        </>
    )
}

export default Groups;
