import { useState } from "react";

export const stripLongString = (longStr: string, wordCount: number) => {
  
    const words = longStr.split(/\s+/);
    if (words.length > wordCount) {
        return words.slice(0, wordCount).join(" ") + "...";
    } else {
        return longStr;
    }
}

export function removeHTMLTagsAndURLs(inputString: string) {
    // Regular expression to match HTML tags and URLs
    var htmlTagRegex = /<\/?[^>]+(>|$)/g;
    var urlRegex = /(http|https):\/\/[^\s$.?#].[^\s]*\b/g;

    // Remove HTML tags
    var cleanString = inputString.replace(htmlTagRegex, '');

    // Remove URLs
    cleanString = cleanString.replace(urlRegex, '');

    return cleanString;
}

const servicesData = [
    {
        path: "/services/confirmation",
        title: "Confirmation",
        description: "Our Confirmation Ministry is dedicated to nurturing the faith of middle school-aged children, guiding them through an important stage of their spiritual journey. The ministry meets every Sunday at noon, both in-person at the church and online, providing flexible options for participation. This inclusive approach ensures that all children can engage deeply with their faith, regardless of their circumstances. Parents and guardians can send requests for participation.",
        haveLink: true,
        buttonTitle: "REQUEST INFO",
        imageUrl: require('../assets/Sacrament-2-IYK.jpg')
    },
    {
        path: "/services/prayer-meetings",
        title: "Prayer Meetings",
        description: "Join us for our weekly prayer meetings, a time dedicated to communal prayer and spiritual growth. These meetings are held every Wednesday evening at 8 PM online. All are welcome to come and lift their hearts in prayer, seeking guidance, healing, and strength from the Lord.",
        haveLink: true,
        buttonTitle: "JOIN NEXT MEETING",
        imageUrl: require('../assets/Prayer-IYK.jpg')
    },
    {
        path: "/services/sunday-services",
        title: "Sunday Services",
        description: "Our Sunday Services offer a vibrant and uplifting worship experience for all ages. Join us every Sunday at 1 PM for a time of worship, teaching, and fellowship. Whether you attend in person or online, you'll find a warm and welcoming community ready to share God's word and love. Don't miss our special children's and youth programs, designed to engage and inspire the younger members of our congregation.",
        haveLink: true,
        buttonTitle: "JOIN US",
        imageUrl: require('../assets/Sunday-Service-IYK.jpg')
    },
    {
        path: "/services/baptism",
        title: "Baptism",
        description: "Baptism is a significant milestone in the life of a believer. Our Baptism Service is an opportunity to publicly declare your faith in Jesus Christ and celebrate this important step with your church family. We hold baptism services quarterly, and preparation classes are available to help you understand the meaning and significance of this sacrament. To register for the next baptism service, please sign-up below.",
        haveLink: true,
        buttonTitle: "SIGN UP",
        imageUrl: require('../assets/Baptism-1-IYK.jpg')
    },
    {
        path: "/services/morning-glory",
        title: "Morning Glory",
        description: "Morning Glory is our early morning prayer service, shared every morning on weekdays on our YouTube channel. This is a powerful time to start your day with prayer, worship, and reflection on God's word. Whether you're a morning person or simply seeking a way to deepen your spiritual practice, Morning Glory provides a peaceful and encouraging start to your day. Join us in person or online, and for more information.",
        haveLink: true,
        buttonTitle: "SUBSCRIBE",
        imageUrl: require('../assets/Morning-Glory-IYK.jpg')
    },
    {
        path: "/services/overnight-prayers",
        title: "Overnight Prayers",
        description: "Our Overnight Prayer meetings are held on the last Friday of every month, from 11 PM to midnight. This extended time of prayer and worship is a powerful opportunity to seek God's presence and intercede for our community and world. Participants can join at the church or online, experiencing the transformative power of prayer throughout the night.",
        haveLink: true,
        buttonTitle: "JOIN NEXT SESSION",
        imageUrl: require('../assets/bible-study.jpg')
    },
    {
        path: "/community/food-pantry",
        title: "Food Pantry",
        description: "Our Food Pantry is committed to providing essential food supplies to those in need within our community. Open every Saturday from 10 AM to 2 PM, the pantry offers a variety of nutritious groceries to help support families and individuals facing food insecurity. Whether you're in need or want to volunteer, we welcome you to join us in this vital ministry. For more information, donation, or to request assistance, click the button below.",
        haveLink: true,
        buttonTitle: "GET HELP",
        imageUrl: require('../assets/community-impact.jpg')
    },
    {
        path: "/community/car-wash",
        title: "Car Wash",
        description: "Our Car Wash service is not just about getting your car clean; it's a community event where we come together to raise funds for various church activities and outreach programs. Held once over the Summer, it's a fun and practical way to support our mission. Come get your car washed and enjoy some fellowship with us.",
        haveLink: true,
        buttonTitle: "SUPPORT US",
        imageUrl: require('../assets/Car-Wash-IYK.jpg')
    },
    {
        path: "/community/neighborhood-cleanup",
        title: "Neighborhood Clean-up",
        description: "Join us for our community cleanup events, where we work together to make our neighborhoods cleaner and safer. These events take place once at the peak of the Fall season every year at a selected date, time, areas. Volunteers of all ages are welcome to participate, helping to pick up litter, remove debris, and beautify public spaces. This is a great opportunity to give back to the community and make a tangible difference.",
        haveLink: true,
        buttonTitle: "VOLUNTEER",
        imageUrl: require('../assets/Community-Clean-Up-IYK.jpeg')
    },
    {
        path: "/community/youth-kids-soccer",
        title: "Youth Kids Soccer",
        description: "Our Youth Kids Soccer Team is a fantastic opportunity for children to develop their athletic skills, learn teamwork, and build friendships in a supportive environment. Practices are held every Monday, Tuesday and Friday from 6 PM to 7:30 PM, with matches on Saturdays. Open to children ages 8-13, the team is a fun and engaging way for kids to stay active and healthy. Click the button below to request more information.",
        haveLink: true,
        buttonTitle: "JOIN TEAM",
        imageUrl: require('../assets/Kids-Soccer-IYK-2.jpeg')
    }
];

export default servicesData;
