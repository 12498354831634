import './styles.css';

import React from 'react';

const Events: React.FC = () => {
    return (
        <>
            <div id='media' className="main-container-events ">
                <div className='left-column-events'>
                    <div className='events-title'>
                        <h1>Events</h1>
                        <p>Discover upcoming events for fellowship, learning, and community engagement</p>
                        <div className='button-submit-events'>
                            <a href='/events' style={{

                                height: '30px'
                            }}> <button>UPCOMING</button></a>
                        </div>
                    </div>
                </div>
                <div className='right-column-events'>
                    <img src={require('../../../assets/Events-1-IYK.jpg')} className='events' />
                </div>
            </div>
        </>

    )
}

export default Events;