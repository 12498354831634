import './styles.css';

import { Link } from 'react-router-dom';
import React from 'react'

const NewsLetter: React.FC = () => {
    return (
        <div id='media' className="main-container-newsletter ">
            <div className='right-column-newsletter'>
                <img src={require('../../../assets/Watoto-IYK-2.jpg')} className='newsletter' />
            </div>
            <div className='left-column-newsletter'>
                <div className='newsletter-title'>
                    <h1>Newsletter</h1>
                    <p>Stay updated with our latest news, events, and inspiring stories through our informative newsletter</p>
                    <div className='button-submit-newsletter'>
                        <Link to="/form" state={{
                            "title": "Join Our Newsletter",
                            "additionalDetail": "Any additional info?"
                        }}
                            onClick={() => { window.scrollTo(0, 0) }}
                        >
                            <button>JOIN</button>
                            </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewsLetter;